import React from "react";
import "../../styles/Page.scss";
import Layout from "../../components/Layout";
import Note from "../../components/Note";

import { ManageAccounts } from "@mui/icons-material";
import acces from "../../content/guides/images/acces-profil.png";
import mdp from "../../content/guides/images/bloc-mdp.png";
import lang from "../../content/guides/images/bloc-langue.png";
import version from "../../content/guides/images/bloc-version.png";

export default function GuideProfile() {
  return (
    <Layout title="Gestion du profil">
      <div className="page-body">
        <div className="page-title guide">
          <ManageAccounts />
          <h1>Gestion du profil</h1>
        </div>
        <p>
          Ce guide a pour objectif de vous montrer comment accéder à vos
          informations personnelles en tant qu'utilisateur de Dotter.science et
          les modifier.
        </p>
        <section>
          <h2 id="accéder-au-profil-utilisateur">
            Accéder au profil utilisateur
          </h2>
          <p>
            En haut du menu principal, cliquez sur <b>votre nom</b> pour ouvrir
            les sous-menus et cliquez sur l'onglet <b>Mon profil</b>.
          </p>
          <img src={acces} alt="Onglet Mon profil" className="centered" />
        </section>
        <section>
          <h2 id="modifier-mes-informations-personnelles">
            Modifier mes informations personnelles
          </h2>
          <p>
            Lors de la première connexion et dans le cas de changements de
            situation importants, il est recommandé de compléter les information
            relatives à votre profil. Les informations personnelles sont
            affichées dans le premier bloc de la page :
            <li>
              <b>Adresse e-mail</b>
            </li>
            <li>
              <b>Prénom</b>
            </li>
            <li>
              <b>Nom</b>
            </li>
            <li>Adresse</li>
            <li>Code postal</li>
            <li>Ville</li>
            <li>Pays</li>
            <li>Téléphone</li>
            <li>Fax</li>
            <li>Établissement</li>
            <li>Société</li>
            <br />
            Cliquez sur un champ pour le modifier puis cliquez sur le bouton{" "}
            <b>Modifier</b> en bas du bloc pour sauvegarder vos changements.
          </p>
          <Note type="caution">
            Il est impossible de modifier le champ <b>adresse e-mail</b>, il
            s'agît de celle que vous avez utilisé pour votre inscription ou via
            laquelle vous avez été invité à utiliser Dotter.science. En cas de
            perte de votre mot de passe, il s'agît de l'adresse e-mail que vous
            devrez renseigner. Veillez donc à disposer d'un accès à cette
            adresse.
          </Note>
        </section>
        <section>
          <h2 id="modifier-mon-mot-de-passe">Modifier mon mot de passe</h2>
          <p>
            Vous pouvez à tout moment modifier votre mot de passe d'accès à
            Dotter.science. Pour cela, rendez-vous au niveau du bloc{" "}
            <b>Mot de passe</b>.
          </p>
          <img src={mdp} alt="Bloc Mot de passe" className="centered" />
          <p>
            Renseignez votre mot de passe actuel puis le nouveau mot de passe
            que vous souhaitez appliquer. Il est conseillé d'utiliser un mot de
            passe de 8 caractères minimum contenant des lettres majuscules et
            minuscules, des chiffres et des caractères spéciaux. Répétez votre
            mot de passe puis cliquez sur <b>Modifier</b>. Un message vous
            confirmera la bonne modification de votre mot de passe.
          </p>
        </section>
        <section>
          <h2 id="langue-d-affichage-et-version-de-dotter-science">
            Langue d'affichage et version de Dotter.science
          </h2>
          <p>
            Dotter.science existe à ce jour en français et en anglais. Pour
            modifier la langue d'affichage, allez sur le bloc{" "}
            <b>Préférence de langue</b> et cliquez sur le champ{" "}
            <em>Choix de la langue</em>. Un menu déroulant vous propose alors de
            passer Dotter.science en <em>Français</em>, <em>anglais</em> ou{" "}
            <em>Détection automatique</em> (le choix se fera alors en fonction
            de votre navigateur). Cliquez sur <b>Modifier</b> pour confirmer
            votre choix.
          </p>
          <img
            src={lang}
            alt="Bloc Préférence de langue"
            className="centered"
          />
          <Note type="note">
            Les traductions ne s'appliquent que sur les textes directement liés
            à la plateforme, elles ne s'appliqueront pas sur des données entrées
            par des utilisateurs comme des réponses aux formulaires ou des noms
            de centres par exemple.
          </Note>
          <p>
            Dotter.science évolue régulièrement et présente un nombre
            grandissant de versions. Vous pouvez connaître la version que vous
            utilisez actuellement en consultant le bloc <b>Version</b>. Cette
            information peut être utile en cas de signalement de bug ou besoin
            d'assistance.
          </p>
          <img src={version} alt="Bloc Version" className="centered" />
          <Note type="tip">
            Vous pouvez également consulter les Conditions Générales
            d'Utilisation (CGU) depuis ce bloc en cliquant sur le lien en bleu.
          </Note>
        </section>
      </div>
    </Layout>
  );
}
